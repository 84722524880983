<template>
  <div class="animated fadeIn">
    <h2 class="mb-4">
      {{ contractorData.name }}
    </h2>
    <h6 v-if="contractorData.email">
      Email
    </h6>
    <p v-if="contractorData.email">
      <a :href="'mailto:' + contractorData.email">{{ contractorData.email }}</a>
    </p>
    <h6 v-if="contractorData.INN">
      ИНН
    </h6>
    <p v-if="contractorData.INN">
      {{ contractorData.INN }}
    </p>
    <h6 v-if="contractorData.phone">
      Контактный телефон
    </h6>
    <p v-if="contractorData.phone">
      <a :href="'tel:' + contractorData.phone">{{ contractorData.phone }}</a>
    </p>
    <h6 v-if="contractorData.info">
      Краткая информация о компании
    </h6>
    <p v-if="contractorData.info">
      {{ contractorData.info }}
    </p>
    <ul v-if="verificationStatus !== 0 && contractorValidationDocuments.length">
      <li
        v-for="document in contractorValidationDocuments"
        :key="document.id"
      >
        <a :href="'/files/' + document.document.file.guid">{{ document.document.documentType.name }}</a>
      </li>
    </ul>

    <b-form-textarea
      v-if="verificationStatus === 2"
      v-model="message"
      type="text"
      name="message"
      rows="4"
      placeholder="Сообщение пользователю"
      no-resize
    />

    <div class="fixed-buttons">
      <b-button
        type="button"
        variant="secondary"
        class="mr-2"
        :class="{'loading' : loading}"
        @click="$router.go(-1)"
      >
        Назад
      </b-button>
      <b-button
        v-if="verificationStatus === 1 && expressDriverCheck === false"
        class="mr-2"
        variant="primary"
        :disabled="loading"
        :class="{'loading' : loading}"
        @click="onAllowExpressDriverCheck"
      >
        Разрешить экспресс проверку водителей
      </b-button>
      <b-button
        v-if="verificationStatus === 1 && expressDriverCheck === true"
        class="mr-2"
        variant="danger"
        :disabled="loading"
        :class="{'loading' : loading}"
        @click="onDisallowExpressDriverCheck"
      >
        Запретить экспресс проверку водителей
      </b-button>
      <b-button
        v-if="verificationStatus === 2"
        class="mr-2"
        variant="primary"
        :disabled="loading"
        :class="{'loading' : loading}"
        @click="onVerify"
      >
        Верифицировать
      </b-button>
      <b-button
        v-if="verificationStatus === 2"
        variant="danger"
        :disabled="loading"
        :class="{'loading' : loading}"
        @click="onReject"
      >
        Отказать
      </b-button>
    </div>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {customersContractorRead, changeExpressDriverCheck, customersContractorVerify, customersContractorReject} from '../../services/api';
import {saveAs} from 'file-saver';

export default {
  name: 'VerificationContractorEdit',
  mixins: [notifications],
  props: ['contractorId'],
  data: function() {
    return {
      loading: false,
      verificationStatus: 0,
      contractorData: {
        name: '',
        phone: '',
        info: '',
        email: '',
      },
      contractorValidationDocuments: {},
      message: '',
      expressDriverCheck: false,
    };
  },
  mounted() {
    this.customersContractorRead();
  },
  methods: {
    async customersContractorRead() {
      this.loading = true;
      const response = await customersContractorRead(this.$store.state.user.ownerId, this.contractorId);
      if (response && response.status === 200) {
        this.contractorData.name = response.data.contractor.name;
        this.contractorData.email = response.data.contractor.email;
        this.contractorData.INN = response.data.contractor.INN;
        this.contractorData.phone = response.data.contractor.phone;
        this.contractorData.info = response.data.contractor.info;
        this.contractorValidationDocuments = response.data.contractor.contractorValidationDocuments;
        this.verificationStatus = response.data.verificationStatus;
        this.expressDriverCheck = response.data.expressDriverCheck;
      }
      this.loading = false;
    },
    getFormData: function(object) {
      const str = [];
      for (const p in object) {
        if (object.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]));
        }
      }
      return str.join('&');
    },
    async onVerify() {
      this.loading = true;
      const response = await customersContractorVerify(
        this.$store.state.user.ownerId,
        this.contractorId,
        this.getFormData({comment: this.message}),
      );
      if (response && response.status === 200) {
        this.showSuccess('Пользователь успешно верифицирован');
        this.$router.push({path: '/contractor-list'});
      }
      this.loading = false;
    },
    async onReject() {
      if (this.message) {
        this.loading = true;
        const response = await customersContractorReject(
          this.$store.state.user.ownerId,
          this.contractorId,
          this.getFormData({comment: this.message}),
        );
        if (response && response.status === 200) {
          this.showSuccess('Пользователю отказано в верификации');
          this.$router.push({path: '/contractor-list'});
        }
        this.loading = false;
      } else {
        this.showError('При отказе в верификации, поле «Сообщение пользователю» должно быть заполнено');
      }
    },
    async onAllowExpressDriverCheck() {
      this.loading = true;
      const response = await changeExpressDriverCheck(
        this.$store.state.user.ownerId,
        this.contractorId,
        this.getFormData({expressDriverCheck: true}),
      );
      if (response && response.status === 200) {
        this.showSuccess('Разрешение на экспресс проверку водителей выдано');
        this.$router.push({path: '/contractor-list'});
      }
      this.loading = false;
    },
    async onDisallowExpressDriverCheck() {
      this.loading = true;
      const response = await changeExpressDriverCheck(
        this.$store.state.user.ownerId,
        this.contractorId,
        this.getFormData({expressDriverCheck: false}),
      );
      if (response && response.status === 200) {
        this.showSuccess('Разрешение на экспресс проверку водителей отменено');
        this.$router.push({path: '/contractor-list'});
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>
